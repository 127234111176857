// extracted by mini-css-extract-plugin
export var anchor = "faq-module--anchor--fyihf";
export var body = "faq-module--body--5P-wq";
export var close = "faq-module--close--Jyz8z";
export var content = "faq-module--content--8Kvua";
export var faq = "faq-module--faq--5f8ed";
export var heading = "faq-module--heading--q35KM";
export var panel = "faq-module--panel--d0FB-";
export var question = "faq-module--question--tah3L";
export var tab = "faq-module--tab--ZWvar";
export var title = "faq-module--title--d3SUw";